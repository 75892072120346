import QrCode, { QrCodeGrid } from "components/QrCode";
import { CoffeeShops } from "coffeeShops";

export default function Qa() {
  return (
    <QrCodeGrid>
      {CoffeeShops.qa
        .filter(({ identifier }) => Boolean(identifier))
        .map(({ name, identifier }) => (
          <QrCode
            key={name}
            url={`https://app.coffeedao.net/#/?spending=${identifier}`}
            title={`QA - ${name}`}
          />
        ))}
    </QrCodeGrid>
  );
}
