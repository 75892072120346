import React, { useEffect, useRef, useState } from "react";
import { createQrCodeStyling } from "components/QrCode";

const qrCode = createQrCodeStyling();

export default function App() {
  const [url, setUrl] = useState("https://qr-code-styling.com");
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  const onUrlChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };

  const onDownloadClick = () => {
    qrCode.download({
      extension: "jpeg",
    });
  };

  return (
    <div className="App">
      <h1>Create QR Code</h1>
      <div style={styles.inputWrapper}>
        <input value={url} onChange={onUrlChange} style={styles.inputBox} />
        <button onClick={onDownloadClick}>Download</button>
      </div>
      <div ref={ref} />
    </div>
  );
}

const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20,
  },
};
