export const CoffeeShops = {
  prod: [
    {
      name: "CafeAddict",
      identifier:
        "1a6796b9172b4bd8b6a11fa65258c86e577f0695a65ec70598062e77a2161330f7e0285360afe672486740f27ce4fe49306ff6c8a07cedf52a0763ed48fd24c1",
    },
    {
      name: "CafeHaaHim",
      identifier:
        "e03070319d989a748f91bd09cde4ba71eb2db93af220f6b1768731b55ba6a784ec2ca6f827cd9c947f20f2c563a528489a04663a4b24b99d392c35942e0358b7",
    },
    {
      name: "CafePawsitive",
      identifier:
        "56df18238d8d11d4c754a20aedf15f8e06fe83278ff64dc9485c77b6493773785d7d622973a57ad35f5192e3bb7c7575b911856e826b94c49c68d0e9ecce318c",
    },
    {
      name: "CafeTsuzuku",
      identifier:
        "e9d5b7cb2011220c5aa6d23e66ccb8f7e7915a536f10e786eb9eabd74996b150e33c4c574a882a2fe5c7fb251efc9c4e4b5f20126c78f06883cc090b3cbc7eae",
    },
    {
      name: "CoastCoffee(CWB)",
      identifier:
        "2b9a40cf45ce6374f6f615184a63d622b3bbd1c133caef7d0b6bb845b6ee6b4e156f4829e805f6298558942d6d9718e53bdb6804af46f1be3c39837dccd546ce",
    },
    {
      name: "CoastCoffee",
      identifier:
        "ec5089f5ed0a90922ba0869e1dfc35374befebcf887f9ce09aec6da7eb94c8bce01f994d03b8a67bca35102fc9cc5debfe60683d8686a8f3d3d911e817e6138e",
    },
    {
      name: "CoffeeAnalog",
      identifier:
        "90a08a7eecc71d631542a4a79e12ec8a26576e925e902fe491773b232b86dc21d56403ede5982f629e22b83b7c9d17c4199a7a7a3622037f1dd1a99a0eb931e2",
    },
    {
      name: "DustingCoffee",
      identifier:
        "52d5bfbad7cd04d02fb7bed935f39f17da326f89215e1fccce0a6881a4034ba74f0e8e88f5ce20f16d71d9d39443b2d3d317b40c9d6955cc2c2e6b6e99510b2e",
    },
    {
      name: "RevTime",
      identifier:
        "14a219cdd69e01922d907b8ef4498b4bae6d7b15e1b964f52b98b5651d180e6062b3d842e428b5a268ff8d2acb4634e3daf821bd861b7e12b687108c4784ab4f",
    },
    {
      name: "SoCoffee&Gin",
      identifier:
        "bb6ab1f5b7b8a6ba3ee5953735673696ddaff8c79d7767c76d7eff6162e2c1fe46c8b470b2a4bc88b8e8dc57e238e85252791f6673a1bf15c94ee912fb5b2cbd",
    },
    {
      name: "TheBathtub",
      identifier:
        "0297f5367a28ae491a564a2bd60a268dcfc84dd934880451d57dd00f8fd3fafdee2222ad17cb983c165df6f7c2e5c908a6b78bdad7ab9daf7ce25bd4c7e4f874",
    },
    {
      name: "ZendoCafe",
      identifier:
        "86e3815f23e64f082f48314dfc08dfc73635c688d480557e14e7ce6bda99c62aa8e7e82a6da4b96821bcefe3910f78c67f30fd96565c7e03a4cd013abace0c68",
    },
    {
      name: "AskCoffee",
      identifier:
        "ed33a173a9f65bab32bc7dc5cb707ce33344c14e8be30b01f9881a2ec30a2e2e707cdc6aa59b03871669f6eacf46ff00b798bec20df96ce194e05cb70fa01b02",
    },
    {
      name: "CafeRen",
      identifier:
        "59bdcb26a2d45b620fc04314e2167bc5596e11183d56e03dc27bfa648d68bf16e7095c54ce8e05c2a65e183b7c529db7738785bec7e79e14b5c35834eaa5575a",
    },
    {
      name: "ChyeSengHuatHardware",
      identifier:
        "7986c420f4e4c0f15014b9664f950effd0d8e32a285fb9f7e8f96412540d992753de5a515024acbb694e87ebc9c12459532295e2079d6c39c82b52a5bf47112a",
    },
    {
      name: "PPPCoffee",
      identifier:
        "a8852180da9a7d3981fe6cfd67eb6e35d2562a9629bfe742f1a56d2000389c0b350a241604e749eb38b2e820ad57b2ecb5a655a3b488da7342ab07ed58a86092",
    },
  ],
  qa: [
    {
      name: "Coffee Addict",
      identifier:
        "216b91cc86db0fd28878385b3c6238d0580e519c2557753f42cc227bdbd9ed63c6f244c507b5023acd6c6eed53a3aa389ccf6aed949a5af5e1a4ec4d0f012859",
    },
    {
      name: "Slow Walker",
      identifier:
        "234be29e9410dd78d9a0f58e428328ecdffb8200f07cb02a01f308d714ae960b5096b5fb11c9dc749df49331a73a1ebad18e0427bcb50b4e928a3c25b7a9ea55",
    },
  ],
};
