import QRCodeStyling from "qr-code-styling";
import { useEffect, useMemo, useRef } from "react";
import logo from "assets/coffeeDao-icon.svg";
import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

interface Props {
  url: string;
  title?: string;
}

export default function QrCode({ url, title = "QR code" }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const qrCode = useMemo(() => createQrCodeStyling(), []);

  useEffect(() => {
    if (ref.current) qrCode.append(ref.current);
  }, [qrCode]);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url, qrCode]);

  const onClickDownloadQrCode = () => {
    qrCode.download({
      extension: "svg",
    });
  };

  return (
    <Container>
      <h3>{title}</h3>
      <div ref={ref} />
      <button onClick={onClickDownloadQrCode}>download</button>
    </Container>
  );
}

export const QrCodeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
`;

export function createQrCodeStyling() {
  return new QRCodeStyling({
    width: 300,
    height: 300,
    image: logo,
    type: "svg",
    dotsOptions: {
      color: "#1E5663",
      type: "extra-rounded",
    },
    cornersSquareOptions: {
      color: "#1E5663",
      type: "extra-rounded",
    },
    backgroundOptions: {
      color: "#FFFFFF",
    },
    imageOptions: {
      imageSize: 0.4,
      crossOrigin: "anonymous",
      margin: 0,
    },
    qrOptions: {
      errorCorrectionLevel: "M",
    },
  });
}
