import { Link, Outlet } from "react-router-dom";
import { styled } from "styled-components";

export { default as Home } from "./Home";
export { default as Prod } from "./Prod";
export { default as Qa } from "./Qa";

const Container = styled.div`
  padding: 32px;
`;

const Nav = styled.nav`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

const NavLink = styled(Link)`
  width: auto;
  height: 32px;
  padding: 8px 12px;
`;

export default function Root() {
  return (
    <Container>
      <Nav>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/prod">Prod</NavLink>
        <NavLink to="/qa">Qa</NavLink>
      </Nav>
      <Outlet />
    </Container>
  );
}
