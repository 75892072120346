import "./App.css";
import Root, { Home, Qa, Prod } from "pages";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "/", element: <Home /> },
      { path: "prod", element: <Prod /> },
      { path: "qa", element: <Qa /> },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
